import { Component, OnInit, ViewEncapsulation, ViewChild, Inject, Optional, ElementRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormsModule, NgForm, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';

import { fuseAnimations } from '@fuse/animations';
import { Router, RouterModule } from '@angular/router';

import { MembershipService } from '@shared/services/membership/membership.service';
import { NotificationService } from '@shared/services/notification/notification.service';
import { PopoverRef } from '@tinc/components/popover/popover-ref';
import { AlertType } from '@shared/components/alert/alert.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@environments/environment';
import { DetectDeviceService } from '@shared/services/detectDevice.service';
import { BREAKPOINT_NAME } from '@shared/services/breakpoints.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { HelpService } from '@shared/services/help.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IconModule } from '@tinc/components/icon/icon.module';
import { PopoverModule } from '@tinc/components/popover/popover.module';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { RegisterComponent } from '../register/register.component';
declare var gtag: Function;


@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
	standalone: true,
	imports: [
		RouterModule, CommonModule,
		FormsModule, IconModule, PopoverModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		RegisterComponent,
		ForgotPasswordComponent
		//ForgotPasswordModule
	]
})
export class LoginComponent implements OnInit {
	errorMessage: boolean = false;
	login: any = {};
	hide = true;
	@ViewChild( 'loginForm' ) public _loginForm: NgForm;
	@ViewChild( 'pass' ) passwordInput: ElementRef;

	isVisible: boolean = false;
	isMobile: boolean = false;

	private dialogData;
	private popoverRef;
	private dialogRef;

	isRPConnected: boolean = (environment as any).isRPConnected;

	doNotGo2Dashboard = false;
	loginForm: FormGroup;

	constructor(
		private membershipService: MembershipService,
		private router: Router,
		private notify: NotificationService,
		private _detectDevice: DetectDeviceService,
		private _help: HelpService,
		private _bottomSheet: MatBottomSheet,
		@Optional() @Inject(MAT_DIALOG_DATA) data: any,
		@Optional() popoverRef: PopoverRef<LoginComponent>,
		@Optional() dialogRef: MatDialogRef<LoginComponent>,

		private fb: FormBuilder
	) {
		this._detectDevice.subscribeToLayoutChanges().subscribe(observerResponse => {
			if (this._detectDevice.isBreakpointActive(BREAKPOINT_NAME.mobile)) {
				this.isMobile = this._detectDevice.isBreakpointActive(BREAKPOINT_NAME.mobile) ? true : false;
			}
		});
		if (data)
			this.doNotGo2Dashboard = data.doNotGo2Dashboard;
		this.dialogRef = dialogRef;
		this.popoverRef = popoverRef;
	}

	
	ngOnInit() {
		this.loginForm = this.fb.group( {
			email: [ '', [ Validators.required, Validators.email, Validators.pattern( '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$' ) ] ],
			pass: [	'',	[ Validators.required, Validators.minLength( 8 ) ] ]	
		} );
		
		
			
	}
	
	get f() { return this.loginForm.controls; }
	get email() {
		return this.loginForm.controls[ 'email' ];
	}

	get password() {
		return this.loginForm.controls[ 'pass' ];
	}
	

	onLoginSubmit() {
		if ( this.loginForm.valid ) {
			this.errorMessage = null;
			this.membershipService.login( this.loginForm.value ).subscribe( {
				next: (data: any) => {
					this.errorMessage = null;
					// this._fuseNavigationService.reLoadMenu('main');
					if (!this.doNotGo2Dashboard) {
						this.closePopoverRef();
						this.router.navigateByUrl("/pages/dashboard");
					} else {
						// close with true
						this.dialogRef?.close({ openEntry: true });
					}
				},
				error: ( err ) => {
					this.errorMessage = true;
					this.notify.openAlertModal((err.error ? err.error : "An error has occured"), AlertType.ERROR);
				}
			} );
		} else {
			this.notify.openWarningModal("Please fill all fields");
		}
	}

	getIcon() {
		return (this.hide ? 'visibility_off' : 'visibility');
	}

	go2Register() {
		this.closePopoverRef();
		this.router.navigateByUrl("/auth/register");
	}

	closePopoverRef() {
		this.popoverRef?.close();
		//this.dialogRef?.close();
	}

	openHelp() {
		let topic = 'membership/loginWithRP';
		gtag('event', 'helpButtonUsage', {
			'event_category': 'HelpClick',
			'event_label': 'Help Button Click',
			'value': topic
		});

		this._help.htmlHelpDisplay(topic, this._bottomSheet);
	}

	

}
export function patternValidator( regexp: RegExp ): ValidatorFn {
	return ( control: AbstractControl ): { [ key: string ]: any } => {
		const value = control.value;
		if ( value === '' ) {
			return null;
		}
		return !regexp.test( value ) ? { 'patternInvalid': { regexp } } : null;
	};
}
export const domainExtValidation: ValidatorFn = ( control: AbstractControl ): ValidationErrors | null => {
	let emailAddress = control.value;
	// validate email address with regular expression
	if ( emailAddress.match( /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/ ) ) {
		let domainExt = emailAddress.split( "." ).pop();
		const tlds = [ "com", "org", "net", "edu", "gov", "mil", "biz", "info", "name", "museum", "us", "ca", "uk", "de", "jp", "fr", "au", "ru", "it", "nl", "se", "eu", "no", "es", "ch", "dk", "cz", "fi", "ie", "co", "za", "in", "mx", "tw", "be", "at", "hk", "br", "sg", "hu", "gr", "pt", "pl", "id", "my", "cl", "ar", "ro", "vn", "tr", "th", "ae", "nz", "kr", "ph", "ua", "pk", "qa", "sa", "eg", "ve", "il", "is", "bg", "hr", "lt", "sk", "si", "ee", "lv", "cy", "az", "by", "kz", "md", "am", "ge", "uz", "lk", "bd", "mn", "np", "om", "ps", "kw", "qa", "ye", "jo", "lb", "sy", "iq", "ae", "il", "bh", "tn", "ma", "dz", "ly", "eg", "sd", "rw", "et", "tz", "ke", "ug", "zm", "mw", "mw", "ao", "bw", "na", "za", "sz", "gh", "ng", "td", "cf", "cm", "cv", "st", "gq", "ga", "cg", "cd", "ao", "gw", "sc", "mu", "mr", "sn", "ml", "gn", "ci", "bf", "ne", "tg", "bj", "ga", "gm", "sl", "lr", "gh", "gn", "gw", "ci", "bf", "mr", "sn", "ml", "gn", "ci", "bf", "ne", "tg", "bj", "ga", "gm", "sl", "lr", "gh", "gn", "gw", "ci", "bf", "mr", "sn", "ml", "gn", "ci", "bf", "ne", "tg", "bj", "ga", "gm", "sl", "lr", "gh", "gn", "gw", "ci", "bf", "mr", "sn", "ml", "gn", "ci", "bf", "ne", "tg", "bj", "ga", "gm", "sl", "lr", "gh", "gn", "gw", "ci", "bf", "mr", "sn", "ml", "gn", "ci", "bf", "ne", "tg", "bj", "ga", "gm", "sl", "lr", "gh", "gn", "gw", "ci", "bf", "mr", "sn", "ml", "gn", "ci", "bf", "ne", "tg", "bj", "ga", "gm", "sl", "lr", "gh", "gn", "gw", "ci", "bf", "mr", "sn", "ml", "gn", "ci", "bf", "ne", "tg", "bj", "ga", "gm", "sl", "lr", "gh" ];
		if ( tlds.includes( domainExt ) )
			return null;
	}

	return { 'emailError': true };
};